import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Scrollbars } from "react-custom-scrollbars";
import { withResizeDetector } from "react-resize-detector";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import ls from "utils/local-storage";
import Header from "../Header";
import Sidebar from "../Sidebar";
import Spin from "../Spin";
import {
  StyledLayout,
  StyledContent,
  StyledInnerContentWrapper
} from "./Styled";

const Layout = ({
  children,
  authStore,
  sidebarStore,
  rootStore,
  themeStore,
  location,
  height
}) => {
  const { REACT_APP_SIDEBAR_STATE_KEY } = process.env;
  const [isCollapsed, setCollapsed] = useState(sidebarStore.state);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(height);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCollapse = async () => {
    setCollapsed(!isCollapsed);
    await ls.save(REACT_APP_SIDEBAR_STATE_KEY, !isCollapsed);
  };

  const getMinHeight = () => {
    return height ? height - 108 : "100%";
  };

  return (
    <StyledLayout theme={themeStore.theme}>
      <Spin loading={rootStore.pending} />
      <Header
        onCollapse={onCollapse}
        companyName={authStore.contextCompanyName}
        isSuperAdmin={authStore.isSuperAdmin}
      />
      <StyledInnerContentWrapper>
        <Sidebar collapsed={isMobile ? true : isCollapsed} location={location} />
        <Scrollbars id="scrollbars" style={{ height: contentHeight }} autoHide>
          <StyledContent
            isMobile={isMobile}
            style={{ minHeight: getMinHeight() }}
          >
            {children}
          </StyledContent>
        </Scrollbars>
      </StyledInnerContentWrapper>
    </StyledLayout>
  );
};
export default compose(
  withResizeDetector,
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    themeStore: rootStore.themeStore,
    sidebarStore: rootStore.sidebarStore,
    rootStore
  })),
  observer
)(Layout);

import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { dateFormatter } from "utils/format";
import P from "components/P";
import Div from "components/Div";
import Icon from "components/Icon";
import DetailCard from "features/miscellany/DetailCard";

const TagDetailCard = ({ tag }) => {
  const fields = [
    ["Roll", tag.roll?.description],
    ["Order", tag.roll?.orderId],
    ["Lot", tag.lot?.name],
    ["Sequence", tag.sequence && String(tag.sequence).padStart(4, "0")],
    ["Created", tag.createdAt && dateFormatter(tag.createdAt)]
  ];
  const chip = {
    type: tag.roll?.tagType || "nfc",
    make: tag.roll?.tagManufacturer || "make",
    model: tag.roll?.tagModel || "model",
    mid: tag.manufacturerId || "000000000000"
  };

  const Banner = () => (
    <Div bottom={6} direction="column" align="center">
      <Icon width={19} height={19} icon={chip.type} />
      <P top={3} size={10} uppercase color="rgba(0,0,0,0.65)">
        {chip.type}
      </P>
      <P size={12} weight="600" color="rgba(0,0,0,0.65)">
        {chip.make + "/" + chip.model}
      </P>
      <P size={10} uppercase color="rgba(0,0,0,0.65)">
        {chip.mid}
      </P>
    </Div>
  );

  return (
    <DetailCard banner={<Banner />} title={tag.id} small fields={fields} />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(TagDetailCard);

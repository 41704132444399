import React, { useEffect } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Header } from "components/Content";
import Accordion from "components/Accordion";
import Div from "components/Div";
import { Button, Link } from "components/actions";
import { useModal } from "components/hooks";
import P from "components/P";
import Modal from "components/Modal";
import withCancelModal from "components/withCancelModal";
import EditProfileForm from "features/profile/forms/EditProfile";
import ChangePasswordForm from "features/profile/forms/ChangePassword";
import {
  accordionTitles,
  firstLoginMessage,
  firstLoginModalTitle
} from "./settings";

const Edit = ({ authStore, onLeavePage }) => {
  const {
    save,
    resetChangePassword,
    getProfile,
    resetEditProfile,
    user
  } = authStore;

  const [
    isFirstLoginModalShown,
    setFirstLoginModalShown,
    onFirstLoginModalOk
  ] = useModal();

  useEffect(() => {
    const mount = async () => await getProfile();
    mount();

    return () => {
      resetChangePassword();
      resetEditProfile();
    };
  }, []);

  useEffect(() => {
    if (user.isFirstLogin) {
      setFirstLoginModalShown()();
    }
  }, [user.isFirstLogin]);

  return (
    <>
      <Modal
        title={firstLoginModalTitle}
        showModal={isFirstLoginModalShown}
        onConfirm={onFirstLoginModalOk(user.toggleFirstLogin)}
      >
        <P>{firstLoginMessage}</P>
      </Modal>
      <Header
        title="Profile"
        actionButtons={[
          <Div justify="flex-end" key="action-buttons">
            <Button type="regularFlat" onClick={onLeavePage(user.isDirty)}>
              Cancel
            </Button>
            <Button type="regular" onClick={save}>
              Save
            </Button>
          </Div>
        ]}
      />
      <Div
        direction="row"
        justify="space-between"
        paddingTop="25px"
        paddingBottom="25px"
      >
        <Accordion titles={accordionTitles} separatorWidth="0px">
          <EditProfileForm />
          <ChangePasswordForm />
          <Div direction="column" align="start" bottom={25}>
            <Div bottom={15}>
              <Link type="greenUnderlined">DIRX2 Terms of Service</Link>
            </Div>
            <Link type="greenUnderlined">DIRX2 Privacy Policy</Link>
          </Div>
        </Accordion>
      </Div>
    </>
  );
};

export default compose(
  withCancelModal(process.env.REACT_APP_REDIRECT_AFTER_LOGIN),
  inject(({ rootStore }) => ({
    authStore: rootStore.authStore,
    rootStore
  })),
  observer
)(Edit);

import React from "react";
import { Popover } from "antd";

import P from "components/P";
import Div from "components/Div";
import {
  segmentFill,
  timelineFill,
  widthChainLink,
  heightChainLink
} from "./settings";

export const Container = ({ n, children, theme }) => (
  <Div justify={n <= 8 ? "center" : "none"} width="100%">
    <Div
      styles={{
        width: `${n * widthChainLink + 80}px`,
        padding: "0 40px",
        background: timelineFill("#fff", "#fcfcfc", theme.mainLighterColor)
      }}
    >
      {children}
    </Div>
  </Div>
);

export const Chain = ({ children }) => (
  <Div
    direction="column"
    styles={{ width: `${widthChainLink}px`, height: `${heightChainLink}px` }}
  >
    {children}
  </Div>
);

export const Segment = ({ i, n }) => (
  <Div
    styles={{
      height: "3px",
      width: "100%",
      background: segmentFill(i, n, "#fff", "#ccc", "#bbb")
    }}
  ></Div>
);

export const Point = ({ i, n, renderActions, theme }) => {
  const size = i === 0 || i === n - 1 ? 18 : 23;
  return (
    <Popover content={renderActions()}>
      <div
        style={{
          position: "absolute",
          top: `calc(50% - ${size * 0.5}px)`,
          left: `calc(50% - ${size * 0.5}px)`,
          width: `${size}px`,
          height: `${size}px`,
          border: `3px solid ${theme.secondaryDarkColor}`,
          borderRadius: "50%",
          backgroundColor:
            i === 0 || i === n - 1 ? theme.secondaryDarkColor : "#fff"
        }}
      ></div>
    </Popover>
  );
};

export const Panel = ({ children }) => (
  <Div justify="center" align="center" styles={{ height: "50%" }}>
    {children}
  </Div>
);

export const Label = ({ title, theme }) => (
  <P size={12} weight="600" color={theme.secondaryDarkerColor}>
    {title}
  </P>
);

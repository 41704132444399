import React from "react";

import MenuIcon from "../MenuIcon";
import MenuItemTitle from "../MenuItemTitle";

const SubMenuTitle = ({ title, icon }) => (
  <span>
    <MenuIcon icon={icon} />
    <MenuItemTitle title={title} />
  </span>
);

export default SubMenuTitle;

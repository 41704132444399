export const accordionTitles = [
  "Details",
  "Password",
  "Terms & Policies"
];

export const firstLoginMessage =
  "Your password has been generated automatically. Before you start, please change your password first. This is necessary for the security of your data. We always worry about the safety of our customers and do our best for the comfortable and safe use of our system.";

export const firstLoginModalTitle = "Change your password";

import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { withResizeDetector } from "react-resize-detector";
import { Scrollbars } from "react-custom-scrollbars";

import { compose } from "utils/hoc";
import Accordion from "components/Accordion";
import Form from "components/Form";
import DeviceSection from "./TemplateDeviceSection";
import HeaderFooterSection from "./TemplateHeaderFooterSection";
import BodySection from "./TemplateBodySection";
import BannerSection from "./TemplateBannerSection";
import { accordionTitles } from "./settings";

const TemplateSteps = ({
  templatesStore: { template },
  isEditRoute,
  height,
}) => {
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    setContentHeight(height);
  }, []);

  return (
    <Scrollbars
      style={{
        height: contentHeight || "100%",
        width: "100%",
      }}
      className="sidebar__scrollbars-wrapper"
      autoHide
    >
      <Form>
        <Accordion
          titles={accordionTitles}
          separatorWidth="80%"
          isEdit={isEditRoute}
          defaultActiveKey={isEditRoute ? [0, 1, 2, 3] : [0]}
          look="template"
          withHeaderSwitch
        >
          <DeviceSection
            template={template}
            disabled={template.config.background.disabled}
            onSwitch={template.changeUsingData("config", "background.disabled")}
          />
          <HeaderFooterSection
            template={template}
            target="header"
            disabled={template.config.header.disabled}
            onSwitch={template.changeUsingData("config", "header.disabled")}
          />
          <BodySection
            template={template}
            disabled={template.config.body.disabled}
            onSwitch={template.changeUsingData("config", "body.disabled")}
          />
          <HeaderFooterSection
            template={template}
            target="footer"
            disabled={template.config.footer.disabled}
            onSwitch={template.changeUsingData("config", "footer.disabled")}
          />
          <BannerSection
            template={template}
            disabled={template.config.banner.disabled}
            onSwitch={template.changeUsingData("config", "banner.disabled")}
          />
        </Accordion>
      </Form>
    </Scrollbars>
  );
};

export default compose(
  withResizeDetector,
  inject(({ rootStore }) => ({
    templatesStore: rootStore.templatesStore,
  })),
  observer,
)(TemplateSteps);

import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { Text, Select } from "components/inputs";
import Icon from "components/Icon";
import Div from "components/Div";
import P from "components/P";
import Form from "components/Form";
import Popover from "components/Popover";

const defaultPopoverInner = (
  <>
    <P bottom={10}>
      This choice determines what action is taken when a tag is considered counterfeit.
    </P>
    <P bottom={10}>
      <b>Use Lot Experience</b> - the user will follow the experience that is assigned to the Lot
    </P>
    <P>
      <b>Use Alternate URL</b> – If this option is selected, the user will be redirected to the URL 
      specified  or get a default counterfeit page for the system if no URL is specified
    </P>
  </>
);

const EditLotCounterfeit = ({
  lot,
  themeStore: { theme },
  rootStore: { validationErrors }
}) => (
  <Form>
    <Div width="100%" direction="column">
      <Div bottom={25}>
        <Select
          label="Lot anti-counterfeiting"
          list={[
            { name: "Enabled", value: "true", icon: "active" },
            { name: "Disabled", value: "false", icon: "inactive" }
          ]}
          inputWidth={"205px"}
          valueKey="value"
          iconKey="icon"
          selectedValue={String(lot.interceptCounterfeit)}
          onChange={v => (lot.interceptCounterfeit = v === "true")}
        />
      </Div>
      <Div direction="row" bottom={15}>
        <Div right={40}>
          <Select
            label={
              <>
                <strong>Alerts</strong>
                <br />
                Receive email alert of rejections?
              </>
            }
            list={[
              { name: "Email", value: "true" },
              { name: "Silent", value: "false" }
            ]}
            inputWidth={"205px"}
            valueKey="value"
            selectedValue={String(lot.configCounterfeit.notifyEmail)}
            disabled={!lot.interceptCounterfeit}
            onChange={v => (lot.configCounterfeit.notifyEmail = v === "true")}
          />
        </Div>
        <Div width="40ch">
          <Text
            label={
              <>
                <br />
                Email address of recipient.
              </>
            }
            value={lot.configCounterfeit.notifyEmailAddress}
            placeholder="username@email.com"
            name="configCounterfeit.notifyEmailAddress"
            disabled={
              !lot.interceptCounterfeit || !lot.configCounterfeit.notifyEmail
            }
            error={validationErrors.email}
            errorMessage="Email not valid or not unique"
            onChange={lot.change}
            required
          />
        </Div>
      </Div>
      <Div direction="row" bottom={25}>
        <Div right={40}>
          <Select
            label="Counterfeit behavior"
            tooltip={
              <Popover.InfoPopover placement="right">
                {defaultPopoverInner}
              </Popover.InfoPopover>
            }
            list={[
              { name: "Use Alternate URL", value: "failure" },
              { name: "Use Lot Experience", value: "warning" }
            ]}
            inputWidth={"205px"}
            valueKey="value"
            selectedValue={lot.configCounterfeit?.behavior || "warning"}
            disabled={!lot.interceptCounterfeit}
            onChange={v => (lot.configCounterfeit.behavior = v)}
          />
        </Div>
        <Div width="40ch" left={8}>
          <Text
            label={<>Alternate URL</>}
            value={lot.configCounterfeit.alternateUrl}
            placeholder="http://example-alternate-url.com"
            name="configCounterfeit.alternateUrl"
            disabled={
              !lot.interceptCounterfeit ||
              lot.configCounterfeit.behavior !== "failure"
            }
            onChange={lot.change}
          />
        </Div>
      </Div>
      <Div bottom={25}>
        <Div direction="column">
          {/* <Div bottom={25}>
            <Div>
              <Select
                label={
                  <>
                    <strong>Manufacturer Identifier Mismatch</strong>
                    <br />
                    Reject activations where manufacturer ID mismatches the tag's
                    manufacturer ID?
                  </>
                }
                list={[
                  { name: "Disabled", value: "false", icon: "inactive" },
                  { name: "Enabled", value: "true", icon: "active" }
                ]}
                inputWidth={"205px"}
                valueKey="value"
                iconKey="icon"
                selectedValue={String(
                  lot.configCounterfeit.matchTagManufacturerID
                )}
                disabled={!lot.interceptCounterfeit}
                onChange={v =>
                  (lot.configCounterfeit.matchTagManufacturerID = v === "true")
                }
              />
            </Div>
          </Div> */}
          {/*
          <Div bottom={25}>
            <Select
              label={
                <>
                  <strong>Tag Counter Mismatch</strong>
                  <br />
                  Reject activations where tag counter (number of activations)
                  mismatches
                  <br />
                  the system's calculation of activation count.
                </>
              }
              list={[
                { name: "Reject", value: "true", icon: "inactive" },
                { name: "Ignore", value: "false", icon: "active" }
              ]}
              inputWidth={"205px"}
              valueKey="value"
              iconKey="icon"
              selectedValue={String(lot.configCounterfeit.matchTagCounter)}
              // disabled={!lot.interceptCounterfeit}
              disabled={true}
              onChange={v =>
                (lot.configCounterfeit.matchTagCounter = v === "true")
              }
            />
          </Div>
          */}
        </Div>
      </Div>
    </Div>
    <Div right={5} direction="column">
      <Div
        width="50ch"
        align="flex-start"
        styles={{
          padding: "15px",
          border: "1px solid",
          borderColor: theme.secondaryLightColor,
          borderRadius: theme.borderRadius,
          background: theme.mainLighterColor
        }}
      >
        <Icon width="24px" height="auto" icon="qrc" />
        <Div left={15} gap={10} direction="column">
          <P size={12}>
            <strong>Anti-counterfeiting employs native NFC features.</strong>
          </P>
          <P size={12}>
            NFC tags must be programmed with ASCII mirroring enabled for the{" "}
            <strong>manufacturer ID</strong> and <strong>counter</strong>.
          </P>
          <P size={12}>
            We recommend locking NFC tags as read-only for further protection.
          </P>
          <P size={12}>
            Finally, tag management must include the{" "}
            <strong>manufacturer's manifest</strong> when tags are ingested into
            DIRX2.
          </P>
        </Div>
      </Div>
    </Div>
  </Form>
);

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(EditLotCounterfeit);

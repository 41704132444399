import React from "react";

import { StyledForm } from "./Styled";

const Form = ({
  display,
  width,
  height,
  justify,
  align,
  grow,
  direction,
  top,
  right,
  bottom,
  left,
  paddingTop,
  paddingBottom,
  paddingRight,
  paddingLeft,
  gap,
  children
}) => (
  <StyledForm
    display={display}
    width={width}
    height={height}
    direction={direction}
    justify={justify}
    align={align}
    grow={grow}
    top={top}
    right={right}
    bottom={bottom}
    left={left}
    paddingTop={paddingTop}
    paddingBottom={paddingBottom}
    paddingRight={paddingRight}
    paddingLeft={paddingLeft}
    gap={gap}
    onSubmit={e => e.preventDefault()}
  >
    {children}
  </StyledForm>
);

export default Form;

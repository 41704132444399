export const accordionTitles = ["Device", "Header", "Body", "Footer", "Banner"];

export const getTemplateHeaderFooterSectionOptions = (key, { selectedKey, onClick }) => {
  switch (key) {
    case "tabs": {
      return [
        {
          icon: "templateText",
          iconWidth: 22,
          iconHeight: 26,
          title: "General",
          type: "largeSelect",
          key: "general",
          selectedKey,
          onClick
        },
        {
          icon: "templateFont",
          iconWidth: 26,
          iconHeight: 24,
          title: "Text",
          type: "largeSelect",
          key: "font",
          selectedKey,
          onClick
        },
        {
          icon: "templateImage",
          iconWidth: 34,
          iconHeight: 24,
          title: "Image",
          type: "largeSelect",
          key: "image",
          selectedKey,
          onClick
        }
      ];
    }
    case "textLocation": {
      return [
        {
          icon: "templateAlignmentTop",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: 1,
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentBottom",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: 2,
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "verticalTextAlignment": {
      return [
        {
          icon: "templateAlignmentTop",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "start",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentCenter",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "center",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentBottom",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "end",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "textAlignment": {
      return [
        {
          icon: "templateTextLeft",
          iconWidth: 23,
          iconHeight: 18,
          type: "smallSelect",
          key: "left",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateTextCenter",
          iconWidth: 23,
          iconHeight: 18,
          type: "smallSelect",
          key: "center",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateTextRight",
          iconWidth: 23,
          iconHeight: 18,
          type: "smallSelect",
          key: "right",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "imageHorizontalAlignment": {
      return [
        {
          icon: "templateTextLeft",
          iconWidth: 23,
          iconHeight: 18,
          type: "smallSelect",
          key: "0%",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateTextCenter",
          iconWidth: 23,
          iconHeight: 18,
          type: "smallSelect",
          key: "50%",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateTextRight",
          iconWidth: 23,
          iconHeight: 18,
          type: "smallSelect",
          key: "100%",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "imageVerticalAlignment": {
      return [
        {
          icon: "templateAlignmentTop",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "0%",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentCenter",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "50%",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentBottom",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "100%",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    default:
      return [];
  }
};

export const getTemplateBodySectionOptions = (key, { selectedKey, onClick }) => {
  switch (key) {
    case "tabs": {
      return [
        {
          icon: "templateImage",
          iconWidth: 34,
          iconHeight: 24,
          title: "Image",
          type: "largeSelect",
          key: "image",
          selectedKey,
          onClick
        },
        {
          icon: "templateButton",
          iconWidth: 72,
          iconHeight: 24,
          title: "Button",
          type: "largeSelect",
          key: "button",
          selectedKey,
          onClick
        }
      ];
    }
    case "buttonsAlignment": {
      return [
        {
          icon: "templateAlignmentTop",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "flex-start",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentCenter",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "center",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateAlignmentBottom",
          iconWidth: 35,
          iconHeight: 27,
          type: "smallSelect",
          key: "flex-end",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "columnPlacement": {
      return [
        {
          icon: "templateOneColumn",
          iconWidth: 20,
          iconHeight: 30,
          type: "smallSelect",
          key: "one",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateTwoColumn",
          iconWidth: 42,
          iconHeight: 30,
          type: "smallSelect",
          key: "two",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "buttonShape": {
      return [
        {
          icon: "templateButtonRectangular",
          iconWidth: 35,
          iconHeight: 15,
          type: "smallSelect",
          key: "rectangular",
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateButtonRounded",
          iconWidth: 35,
          iconHeight: 15,
          type: "smallSelect",
          key: "rounded",
          selectedKey,
          onClick: onClick
        }
      ];
    }
    case "buttonIconPosition": {
      return [
        {
          icon: "templateButtonIconDisabled",
          iconWidth: 35,
          iconHeight: 15,
          type: "smallSelect",
          key: 0,
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateButtonIconAlignmentLeft",
          iconWidth: 35,
          iconHeight: 15,
          type: "smallSelect",
          key: -1,
          selectedKey,
          onClick: onClick
        },
        {
          icon: "templateButtonIconAlignmentRight",
          iconWidth: 35,
          iconHeight: 15,
          type: "smallSelect",
          key: 1,
          selectedKey,
          onClick: onClick
        }
      ];
    }
    default:
      return [];
  }
};

export const generateSizesList = (size = 24) =>
  Array(size)
    .fill("")
    .map((_, i) => ({ id: i, name: i + 1, value: `${i + 1}` }));

export const fontWeightValues = [
  { id: 0, name: "Thin", value: "300" },
  { id: 1, name: "Regular", value: "400" },
  { id: 2, name: "Medium", value: "500" },
  { id: 3, name: "Bold", value: "700" }
];

export const fontSizeDimensionValues = [
  { id: 0, name: "px", value: "px" },
  { id: 1, name: "pt", value: "pt" },
  { id: 2, name: "em", value: "em" },
  { id: 3, name: "rem", value: "rem" },
  { id: 4, name: "vw", value: "vw" },
  { id: 5, name: "vh", value: "vh" }
];

export const fontFamilyValues = [
  { id: 0, name: "Roboto" },
  { id: 1, name: "Open Sans" },
  { id: 2, name: "Montserrat" },
  { id: 3, name: "Lato" },
  { id: 4, name: "Raleway" },
  { id: 5, name: "Playfair Display" },
  { id: 6, name: "Lora" },
  { id: 7, name: "Rokkitt" },
  { id: 8, name: "Lemonada" },
  { id: 9, name: "Dancing Script" },
  { id: 10, name: "Orbitron" }
];

export const headerFooterHeightValues = [
  { id: 0, name: "auto", value: "1fr" },
  { id: 1, name: "20%", value: "20vh" },
  { id: 2, name: "40%", value: "40vh" }
];

import { observable, action, computed } from "mobx";
import uuid from "uuid/v4";
import { findIndex, sortBy } from "lodash";

import { filesToFormData } from "utils/format";
import Asset from "../models/Asset";
class AssetBundles {
  @observable maxAssetSizeMB = "5";
  @observable maxAssetSizeBytes = 5242880;
  @observable maxAssetQty = 12;
  @observable rawAssetBundle = [];
  @observable bundleId = "";

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawAssetBundle = [];
  }

  @computed
  get assetBundle() {
    return this.rawAssetBundle;
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawAssetBundle = [];
    this.bundleId = "";
    this.rootStore.resetValidationErrors();
  }

  @action findAssetsByBundleId = ({ id }) => async () => {
    const { method, url } = this.rootStore.urls.assets.getByBundleId;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {
        assetBundleId: id
      }
    });

    this.bundleId = id;
    const data = response && response.data;
    if (data) {
      this.rawAssetBundle = sortBy(data, ["createdAt"]).map(
        r => new Asset({ ...r, status: "done" }, this.rootStore)
      );
    }

    return this.rawAssetBundle;
  };

  @action createBundle = data => async () => {
    const { method, url } = this.rootStore.urls.assetBundles.create;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: data
    });

    return response && response.data;
  };

  async upload(target) {
    const file = target.files[0];
    const { method, url } = this.rootStore.urls.assets.create;
    const formData = filesToFormData(target, ["file"]);
    formData.append("assetBundleId", this.bundleId);
    formData.append("name", file.name);
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: formData
    });

    return response && response.data;
  }

  async deleteById(id) {
    const { method, url } = this.rootStore.urls.assets.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    return response;
  }

  @action addAsset = ({ target, relation }) => async () => {
    const file = target.files[0];
    const errors = this.rootStore.validator.validateAsset({
      asset: file,
      maxAssetSize: this.maxAssetSizeBytes,
      qty: this.rawAssetBundle.length,
      maxQty: this.maxAssetQty
    });

    if (this.rootStore.hasValidationErrors(errors)) return;

    if (!this.bundleId) {
      const response = await this.createBundle(relation)();

      if (response) {
        this.bundleId = response.id;
      } else {
        return;
      }
    }

    let newFile = new Asset(
      {
        id: uuid(),
        name: file.name,
        status: "uploading",
        access: this.rootStore.authStore.isSuperAdmin
          ? "super_admin"
          : "company_admin"
      },
      this.rootStore
    );

    this.rawAssetBundle.push(newFile);

    const response = await this.upload(target);

    if (response) {
      newFile.assign("id", response.id);
      newFile.assign("status", "done");
      newFile.assign("file", response.file);
      newFile.assign("createdAt", response.createdAt);
      newFile.assign("access", response.access);
    } else {
      newFile.assign("status", "error");
    }

    const index = findIndex(this.rawAssetBundle, asset => {
      return asset.id === newFile.id;
    });

    this.rawAssetBundle.splice(index, 1, newFile);
  };

  @action deleteAssetById = ({ id }) => async () => {
    const response = await this.deleteById(id);

    if (response) {
      this.rawAssetBundle = this.rawAssetBundle.filter(
        asset => asset.id !== id
      );
    }
  };
}

export default AssetBundles;

import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import Button from "components/actions/Button";
import { StyledTable } from "components/TableFormList/Styled";

const MetadataList = ({ parentStore, themeStore }) => {
  const { metadataArray, deleteMetadataById } = parentStore;
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: 106,
    },
    {
      title: "Value",
      key: "value",
      dataIndex: "value",
      width: 415,
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      width: 75,
      align: "center",
      render: (_, row) => (
        <Button type="bin" onClick={() => {
          deleteMetadataById(row.id);
        }} />
      )
    }
  ];

  return (
    <StyledTable
      style={{ width: "100%" }}
      tableLayout="fixed"
      scroll={{ y: 400 }}
      bordered={true}
      size="middle"
      columns={columns}
      dataSource={[...metadataArray]}
      rowKey="id"
      pagination={false}
      theme={themeStore.theme}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore
  })),
  observer
)(MetadataList);

import { observable, action, computed } from "mobx";

import Lot from "../models/Lot";
import Experience from "../models/Experience";

class Lots {
  @observable rawLots = [];
  @observable rawLot = {};
  @observable params = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.rawLot = this.populateLot({}, rootStore);
  }

  @computed
  get lots() {
    return this.rawLots;
  }

  @computed
  get lot() {
    return this.rawLot;
  }

  populateLot(lot, rootStore) {
    const { experience } = lot;

    return new Lot(
      {
        ...lot,
        experience: new Experience(experience || {}, rootStore)
      },
      rootStore
    );
  }

  @action.bound excludeCurrent(lotId) {
    return this.rawLots.filter(({ id }) => id !== lotId);
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.rawLots = [];
    this.rawLot = this.populateLot({}, this.rootStore);
    this.params = {};
    this.rootStore.resetValidationErrors();
  }

  @action.bound changeParams({ key, value }) {
    this.params = {
      ...this.params,
      [key]: value
    };
  }

  @action.bound onNextPage = config =>
      this.findAll({paginationOptions: config});

  getPaginationParams(paginationConfig) {
    const paginationParams = paginationConfig && {
      limit: paginationConfig.limit,
      offset: paginationConfig.offset
    };

    return paginationParams || {};
  }

  @action.bound async findAll(params, paginationOptions) {
    const { method, url } = this.rootStore.urls.lots.getAll;
    const paginationParams = this.getPaginationParams(paginationOptions);
    const requestParams = params || this.params;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      params: {...requestParams, ...paginationParams}
    });

    if (response) {
      const { rows, count: itemsAmount } = response.data;
      if (paginationOptions?.isScrollPagination) {
        this.rawLots = [...this.rawLots, ...rows.map(l => this.populateLot(l, this.rootStore))];
      } else {
        this.rawLots = rows.map(l => this.populateLot(l, this.rootStore));
      }

      if (paginationOptions) {
        paginationOptions.setTotal(itemsAmount);
      }

      return { data: rows };
    }

    return response;
  }

  @action findById = ({ id }) => async () => {
    const { method, url } = this.rootStore.urls.lots.getById;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    if (response) {
      this.rawLot = this.populateLot(response.data, this.rootStore);
    }

    return response;
  };

  @action save = isEdit => async () => {
    const { method, url } = isEdit
      ? this.rootStore.urls.lots.update
      : this.rootStore.urls.lots.create;

    const body = isEdit ? this.lot.updateData : this.lot.createData;
    const errors = this.rootStore.validator.validateLot(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body
    });

    if (response) {
      if (isEdit) {
        return this.rootStore.routingStore.push("/lots");
      }

      const lot = response.data;
      this.rawLots.push(this.populateLot(lot, this.rootStore));
      return this.rootStore.routingStore.push(`/lots/edit/${lot.id}`);
    }

    return response;
  };

  @action deleteById = id => async () => {
    const { method, url } = this.rootStore.urls.lots.delete;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${id}`
    });

    if (response) {
      this.rawLots = this.rawLots.filter(l => l.id !== id);
    }

    return response;
  };
}

export default Lots;

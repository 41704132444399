import React from "react";

import Button from "components/actions/Button";
import Div from "components/Div";
import Tip from "components/Tip";
import InnerContent from "./InnerContent";

const AssignExperience = ({
  experience,
  onSet = () => null,
  onDelete = () => null,
}) => {
  const hasExperience = experience && experience.id;

  return (
    <Div direction="column">
      <Div bottom={5} align="center">
        <Button type="setExperience" onClick={onSet}>
          <InnerContent
            hasExperience={hasExperience}
            experience={experience}
            defaultTitle="Set default experience"
          />
        </Button>
        {hasExperience && <Button type="bin" onClick={onDelete} />}
      </Div>
      {!hasExperience && (
        <Tip text="This lot doesn't have any experience yet" />
      )}
    </Div>
  );
};

export default AssignExperience;

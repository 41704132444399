import React from "react";

import { dateFormatter } from "utils/format";
import TagOpticsLink from "features/tags/TagOpticsLink";
import { ValueWithDetail } from "../Dashboard/render";

const whenFields = ["when", "createdAt", "timezone"];

const whereFields = [
  "country",
  "countryCode",
  "region",
  "regionCode",
  "postalCode",
  "county",
  "city",
  "houseNumber",
  "street",
  "lat",
  "lon",
  "ipAddress",
  "browserLat",
  "browserLon",
  "browserAccuracy"
];

const addressFields = ["street", "city", "region", "postalCode", "country"];

export const columns = [
  {
    title: "When",
    key: "when",
    dataIndex: "when",
    width: "10vw",
    render: (_, row) => (
      <ValueWithDetail
        value={dateFormatter(row.when)}
        detailFields={whenFields}
        detailValues={whenFields.map(f => row[f])}
      />
    )
  },
  {
    title: "Where",
    key: "where",
    dataIndex: "label",
    align: "center",
    render: (_, row) => (
      <ValueWithDetail
        value={
          row.label ||
          addressFields
            .filter(f => !!row[f])
            .map(f => row[f])
            .join(", ")
        }
        detailFields={whereFields}
        detailValues={whereFields.map(f => row[f])}
      />
    )
  },
  {
    title: "Why",
    key: "why",
    dataIndex: "details.why",
    align: "center",
    width: "7vw"
  },
  {
    title: "Tag",
    key: "tag",
    dataIndex: "tagId",
    align: "center",
    width: "16vw",
    render: (_, row) => <TagOpticsLink id={row.tagId} />
  },
  {
    title: "Device",
    key: "device",
    dataIndex: "deviceId",
    align: "center",
    width: "16vw"
  }
];

import styled from "styled-components";
import { Menu, Layout } from "antd";
import { Link } from "react-router-dom";

const { Sider } = Layout;

export const StyledSiderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
`;

export const StyledSider = styled(Sider)`
  background-color: #fff !important;
`;

export const StyledMenu = styled(Menu)`
  border-right: none !important;

  .ant-menu-item,
  .ant-menu-submenu-title {
    height: auto !important;
    margin: 0 !important;
    line-height: 74px !important;
  }

  .ant-menu-submenu .ant-menu-sub .ant-menu-item {
    line-height: 50px !important;

    span {
      font-size: 16px;
    }
  }

  .ant-menu-item-selected {
    background-color: ${({ apptheme }) => apptheme.mainLightColor} !important;

    span {
      font-weight: bold;
    }
  }

  .ant-menu-item-selected:after {
    border-right: none !important;
  }

  .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-arrow::after {
    background-color: ${({ apptheme }) => apptheme.mainDarkColor} !important;
  }

  .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::before,
  .ant-menu-submenu-title:hover .ant-menu-submenu-arrow::after {
    background: ${({ apptheme }) => apptheme.mainDarkColor} !important;
  }

  span {
    font-size: 16px;
    color: ${({ apptheme }) => apptheme.mainDarkColor};
  }
`;

export const StyledLink = styled(Link)`
  display: flex !important;
  align-items: center;
`;

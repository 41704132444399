import React from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import { dateFormatMMMMDDYYYY } from "utils/format";
import { StyledTable } from "components/TableFormList/Styled";
import AssetName from "./AssetName";
import Actions from "./Actions";

const AssetBundleList = ({ rootStore }) => {
  const { assetBundlesStore, themeStore } = rootStore;
  const { assetBundle, deleteAssetById } = assetBundlesStore;
  const { theme } = themeStore;
  const columns = [
    {
      title: "Name",
      key: "name",
      dataIndex: "name",
      width: 350,
      render: (_, row) => (
        <AssetName
          status={row.status}
          name={row.name}
          color={theme.mainDarkColor}
          errorColor={theme.errorColor}
        />
      )
    },
    {
      title: "Date",
      key: "createdAt",
      dataIndex: "createdAt",
      align: "center",
      width: 102,
      render: (_, row) =>
        row.createdAt ? dateFormatMMMMDDYYYY(row.createdAt) : "N/A"
    },
    {
      title: "Role",
      key: "role",
      dataIndex: "role",
      width: 70,
      align: "center",
      render: (_, row) => <strong>{row.access === "super_admin" ? "S" : "C"}</strong>
    },
    {
      title: "Actions",
      key: "actions",
      dataIndex: "actions",
      width: 75,
      align: "center",
      render: (_, row) => (
        <Actions
          asset={row}
          deleteAsset={() => {
            deleteAssetById({id: row.id})();
          }}
          isSuperAdmin={rootStore.authStore.isSuperAdmin}
        />
      )
    }
  ];

  return (
    <StyledTable
      style={{ width: "100%" }}
      tableLayout="fixed"
      scroll={{ y: 400 }}
      bordered={true}
      size="middle"
      columns={columns}
      dataSource={[...assetBundle]}
      rowKey="id"
      pagination={false}
      theme={themeStore.theme}
    />
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(AssetBundleList);

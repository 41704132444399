import { observable, action, computed, toJS } from "mobx";
import uuid from "uuid/v4";

import { filesToFormData } from "utils/format";

class ExperienceStep {
  @observable id = "";
  @observable buttonName = "";
  @observable url = "https://";
  @observable experienceId = "";
  @observable isMenuLink = false;
  @observable buttonIcon = "";

  constructor(step, rootStore, showExperiencePreview, setExperiencePreview) {
    this.rootStore = rootStore;
    this.showExperiencePreview = showExperiencePreview;
    this.setExperiencePreview = setExperiencePreview;

    Object.assign(this, { id: uuid(), ...step });
  }

  @computed
  get createMenuStep() {
    return toJS({
      buttonName: this.buttonName,
      url: this.url,
      buttonIcon: this.buttonIcon,
    });
  }

  @computed
  get createSequenceStep() {
    if (this.isMenuLink) {
      return toJS({
        experienceId: this.experienceId,
      });
    }

    return toJS({
      url: this.url,
    });
  }

  @action.bound changeButtonName(event) {
    this.buttonName = event.target.value;
  }

  @action.bound changeUrl(event) {
    this.url = event.target.value;
  }

  @action.bound changeExperience(experienceId) {
    this.experienceId = experienceId;
  }

  @action.bound async showPreview() {
    if (this.isMenuLink) {
      return {
        url: this.showExperiencePreview(this.experienceId),
        image: "",
      };
    }

    const { method, url: requestUrl } = this.rootStore.urls.preview.get;
    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${requestUrl}?url=${this.url}`,
    });

    if (response) {
      this.setExperiencePreview(response.data);
    }

    return { url: this.url, image: response.data };
  }

  async uploadImage(target) {
    const fileName = target.files[0].name;
    const { method, url } = this.rootStore.urls.uploadFile;
    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body: filesToFormData(target, ["file"]),
    });

    if (response) return { url: response.data.url, fileName };
  }

  @action changeIcon = data => {
    this.buttonIcon = data;
  };

  @action resetIcon = () => {
    this.buttonIcon = "";
  };
}

export default ExperienceStep;

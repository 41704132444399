import React, { useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";

import { compose } from "utils/hoc";
import geo from "domain/services/geo";
import { createMap, updateLayer, createMarker } from "./leaflet/api";
import { oceanColors, templateContainer } from "./leaflet/settings";
import { strategyColors, markerContent } from "./settings";

const GeoMap = ({
  config = { strategy: "inclusion", zones: [] },
  points = [],
  width,
  height,
  ocean = "blue",
  mapFeaturesOff = [
    "doubleClickZoom",
    "scrollWheelZoom",
    "boxZoom",
    "keyboard",
    "tap"
  ],
  zoomControl = false,
  view = { center: [25, 0], zoom: 1.8 }
}) => {
  const [containerStyle] = useState({
    ...templateContainer,
    width: width,
    height: height,
    background: oceanColors[ocean]
  });
  const [mapOptions] = useState({
    mapFeaturesOff,
    zoomControl,
    center: view.center,
    zoom: view.zoom
  });

  const containerRef = useRef(null);
  const mapRef = useRef(null);
  const standardRef = useRef(null);
  const highlightedRef = useRef(null);
  const markersRef = useRef(null);

  useEffect(() => {
    const { current: container } = containerRef;
    const { map, standard, highlighted, markers } = createMap(
      container,
      mapOptions
    );

    mapRef.current = map;
    standardRef.current = standard;
    highlightedRef.current = highlighted;
    markersRef.current = markers;
  }, [mapOptions]);

  useEffect(() => {
    const selected = [];
    const unselected = [];

    geo.countries.forEach(item => {
      const { properties: { continent, code: country } } = item;
      const found = config.zones.find(
        z =>
          z.continentCode === continent &&
          (z.countryCode === "*" || z.countryCode === country)
      );
      found ? selected.push(item) : unselected.push(item);
    });

    const { current: standard } = standardRef;
    const { current: highlighted } = highlightedRef;

    const fillColor = strategyColors[config.strategy];

    updateLayer(standard, unselected);
    updateLayer(highlighted, selected, { fillColor });
  }, [config.strategy, config.zones]);

  useEffect(() => {
    const { current: markers } = markersRef;

    markers.clearLayers();
    
    points.forEach(
      p =>
        createMarker([p.lat, p.lon])
          .addTo(markers)
          .bindPopup(markerContent(p))
    );
  }, [points]);

  useEffect(() => {
    const { current: map } = mapRef;
    const { center, zoom } = view;

    map.setView(center, zoom);
  }, [view]);

  return <div ref={containerRef} style={containerStyle}></div>;
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(GeoMap);

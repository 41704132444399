import React from "react";
import { inject, observer } from "mobx-react";
import { Empty } from "antd";
import { Scrollbars } from "react-custom-scrollbars";

import { compose } from "utils/hoc";
import { dateFormatter } from "utils/format";
import P from "components/P";
import Div from "components/Div";
import { Container, Chain, Segment, Panel, Point, Label } from "./Styled";

const Timeline = ({
  data,
  idKey = "id",
  dateKey = "createdAt",
  title = "Timeline",
  renderActions,
  themeStore: { theme }
}) => {
  return (
    <Div width="100%" direction="column">
      <Div
        justify="center"
        styles={{
          width: "100%",
          padding: "15px",
          borderTop: "1px solid",
          borderBottom: "1px solid",
          borderColor: theme.secondaryLightColor,
          overflowY: "hidden"
        }}
      >
        <P uppercase weight="bold">
          {title}
        </P>
      </Div>
      <Div
        justify="center"
        align="center"
        styles={{
          width: "100%",
          minHeight: "168px",
          borderBottom: "1px solid",
          borderColor: theme.secondaryLightColor,
          overflowY: "hidden"
        }}
      >
        {!data || !data.length ? (
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        ) : (
          <Scrollbars style={{ width: "100%", userSelect: "none" }} autoHide>
            <Container n={data.length} theme={theme}>
              {data.map((o, i) => (
                <Chain key={o[idKey] + i} theme={theme}>
                  <Panel theme={theme}>
                    {i % 2 === 0 && (
                      <Label title={dateFormatter(o[dateKey])} theme={theme} />
                    )}
                  </Panel>
                  <Segment i={i} n={data.length} theme={theme} />
                  <Panel theme={theme}>
                    {i % 2 === 1 && (
                      <Label title={dateFormatter(o[dateKey])} theme={theme} />
                    )}
                  </Panel>
                  <Point
                    i={i}
                    n={data.length}
                    renderActions={() => renderActions(o)}
                    theme={theme}
                  />
                </Chain>
              ))}
            </Container>
          </Scrollbars>
        )}
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    themeStore: rootStore.themeStore,
    rootStore
  })),
  observer
)(Timeline);

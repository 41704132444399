import React, { useEffect, useRef } from "react";
import { inject, observer } from "mobx-react";
import debounce from "lodash/debounce";

import { compose } from "utils/hoc";
import Table from "components/Table";
import { Button, Link } from "components/actions";
import Modal from "components/Modal";
import {useModal, useTablePaginator} from "components/hooks";
import { Header, Inner } from "components/Content";
import { lotListTableColumns } from "./settings";

const List = ({ lotsStore }) => {
  const {
    lots,
    lot,
    findAll,
    reset,
    findById,
    deleteById,
    changeParams,
    params: { search }
  } = lotsStore;
  const [
    isDeleteModalShown,
    setDeleteModalShown,
    onDeleteModalOk,
    onDeleteModalCancel
  ] = useModal();

  const [pagination, paginationReset] = useTablePaginator({
    pageSize: 10,
    limit: 10,
    onPageChange: (paginationConfig) => {
      findAll(null, paginationConfig);
    }
  });

  const delayedQuery = useRef(
    debounce(() => {
      paginationReset();
      findAll(null, pagination);
    }, 500)
  ).current;

  useEffect(() => {
    findAll({}, pagination);
    return () => {
      reset();
      paginationReset();
    };
  }, [findAll, lotsStore, reset]);

  const onSearch = ({ key, value }) => {
    changeParams({ key, value });
    delayedQuery();
  };

  const renderActions = row => (
    <>
      <Link
        type="popoverRegular"
        to={`/lots/edit/${row.id}`}
        constraintGetter="lots.delete"
      >
        Edit
      </Link>
      <Link
        type="popoverRegular"
        to={`/lots/optics/${row.id}`}
        constraintGetter="lots.read"
      >
        Optics
      </Link>
      <Button
        constraintGetter="lots.delete"
        type="popoverRedText"
        onClick={setDeleteModalShown(findById({ id: row.id }))}
      >
        Delete
      </Button>
    </>
  );

  return (
    <>
      <Modal
        title="Delete lot"
        showModal={isDeleteModalShown}
        onCancel={onDeleteModalCancel()}
        onConfirm={onDeleteModalOk(deleteById(lot.id))}
      >
        {`Are you sure you want to delete ${lot.name} lot?`}
      </Modal>
      <Header
        title="All Lots"
        searchPlaceholder="Search by name"
        actionButtons={[
          <Link type="regular" key="create-btn" to="/lots/create">
            Create Lot
          </Link>
        ]}
        search={search}
        onSearch={onSearch}
        withSearch
        searchDisabled={false} // todo: remake when backend send count of items
      />
      <Inner>
        <Table
          columns={lotListTableColumns}
          data={lots}
          paginationConfig={pagination}
          renderActions={renderActions}
        />
      </Inner>
    </>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    lotsStore: rootStore.lotsStore
  })),
  observer
)(List);

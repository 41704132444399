import { observable, action, computed } from "mobx";
import { isEmpty, get } from "lodash";

import ls from "utils/local-storage";
import AuthUser from "../models/AuthUser";

const {
  REACT_APP_LS_TOKEN_KEY,
  REACT_APP_LS_CONTEXT_COMPANY_KEY,
  REACT_APP_LS_REFRESH_TOKEN_KEY,
  REACT_APP_REDIRECT_AFTER_LOGIN,
} = process.env;

class Auth {
  @observable email = "";
  @observable password = "";
  @observable confirmPassword = "";
  @observable contextCompany = {};
  @observable oldPassword = "";
  @observable newPassword = "";
  @observable repeatPassword = "";
  @observable user = {};
  @observable initialUser = {};

  constructor(rootStore) {
    this.rootStore = rootStore;
    this.user = new AuthUser({}, rootStore);
    this.initialUser = new AuthUser({}, rootStore);
  }

  @computed
  get contextCompanyId() {
    if (!isEmpty(this.contextCompany) || this.user.company) {
      return this.hasContext ? this.contextCompany.id : this.user.company.id;
    }
    return "";
  }

  @computed
  get contextCompanyName() {
    if (this.hasContext) {
      return this.contextCompany.name;
    }

    if (this.user.company && !this.user.company.isCoreCompany) {
      return this.user.company.name;
    }

    return "";
  }

  @computed
  get hasContext() {
    return !!Object.keys(this.contextCompany).length;
  }

  @computed
  get isInContext() {
    return !isEmpty(this.contextCompany);
  }

  @computed
  get isSuperAdmin() {
    return this.user.role === "super_admin";
  }

  @computed
  get isAuthorized() {
    return this.user.id;
  }

  @computed
  get constraints() {
    return get(this.user.company, "constraint.options", {});
  }

  @action.bound reset() {
    this.rootStore.abortRequest();
    this.email = "";
    this.password = "";
    this.confirmPassword = "";
    this.rootStore.resetValidationErrors();
  }

  @action.bound resetEditProfile() {
    this.rootStore.abortRequest();
    this.user = this.initialUser;
    this.rootStore.resetValidationErrors();
  }

  @action.bound resetChangePassword() {
    this.rootStore.abortRequest();
    this.oldPassword = "";
    this.newPassword = "";
    this.repeatPassword = "";
    this.rootStore.resetValidationErrors();
  }

  @action.bound async getProfile() {
    const { method, url } = this.rootStore.urls.profile.get;

    const { response } = await this.rootStore.makeRequest({
      method,
      url
    });

    if (response) {
      this.user = new AuthUser(response.data, this.rootStore);
      this.initialUser = new AuthUser(response.data, this.rootStore);
    }

    return response;
  }

  @action.bound async save() {
    const { method, url } = this.rootStore.urls.profile.update;

    const body = this.user.updateData;
    const errors = this.rootStore.validator.validateProfile(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body
    });

    if (response) {
      this.user = new AuthUser({ ...this.user, ...body }, this.rootStore);
      this.initialUser = new AuthUser(
        { ...this.initialUser, ...body },
        this.rootStore
      );

      return this.rootStore.routingStore.push(
        process.env.REACT_APP_REDIRECT_AFTER_LOGIN
      );
    }

    return response;
  }

  @action switchContext = companyId => async () => {
    if (this.hasContext) {
      this.contextCompany = {};
      await ls.remove(REACT_APP_LS_CONTEXT_COMPANY_KEY);
    } else {
      const errors = this.rootStore.validator.validateSwitchContext({
        companyId
      });
      if (this.rootStore.hasValidationErrors(errors)) return;

      const { method, url } = this.rootStore.urls.auth.getCompanyContext;
      const { response } = await this.rootStore.makeRequest({
        method,
        url,
        body: {
          companyId,
          refresh: await ls.get(REACT_APP_LS_REFRESH_TOKEN_KEY)
        }
      });

      if (!response) return;

      const { tokens, company } = response.data;
      this.contextCompany = { token: tokens.access, ...company };
      await ls.save(REACT_APP_LS_CONTEXT_COMPANY_KEY, {
        token: tokens.access,
        ...company
      });
      await ls.save(REACT_APP_LS_REFRESH_TOKEN_KEY, tokens.refresh);
    }

    setTimeout(
      () => window.location.replace(REACT_APP_REDIRECT_AFTER_LOGIN),
      200
    );
  };

  @action.bound async change(event) {
    this[event.target.name] = event.target.value;
  }

  @action.bound async login() {
    const body = {
      email: this.email,
      password: this.password
    };
    const errors = this.rootStore.validator.validateLogin(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { method, url } = this.rootStore.urls.auth.login;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body
    });

    if (response) {
      const { user, tokens } = response.data;

      await Promise.all([
        ls.save(REACT_APP_LS_TOKEN_KEY, tokens.access),
        ls.save(REACT_APP_LS_REFRESH_TOKEN_KEY, tokens.refresh)
      ]);

      this.user = new AuthUser(user, this.rootStore);
      if (user.isFirstLogin) {
        this.rootStore.routingStore.push("/profile");
      } else {
        this.rootStore.routingStore.push(REACT_APP_REDIRECT_AFTER_LOGIN);
      }
    }

    return response;
  }

  @action.bound async changePassword() {
    const body = {
      oldPassword: this.oldPassword,
      newPassword: this.newPassword,
      repeatPassword: this.repeatPassword
    };

    const errors = this.rootStore.validator.validateChangePassword(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { method, url } = this.rootStore.urls.auth.changePassword;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body
    });

    if (response && response.data) {
      this.resetChangePassword();
      this.rootStore.message("Password was successfully changed.", "success");
    }

    return response;
  }

  @action.bound async forgotPassword() {
    const body = { email: this.email };

    const errors = this.rootStore.validator.validateForgotPassword(body);
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { method, url } = this.rootStore.urls.auth.forgotPasswordRequest;

    const { response } = await this.rootStore.makeRequest({
      method,
      url,
      body
    });

    if (response) {
      this.rootStore.message(
        "The password recovery link has been sent to your email.",
        "success",
        { duration: 5 }
      );
      this.rootStore.routingStore.push("/login");
    }

    return response;
  }

  @action.bound async forgotPasswordTokenValidation(token) {
    const {
      method,
      url
    } = this.rootStore.urls.auth.forgotPasswordTokenValidation;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${token}`
    });

    if (response && !response.data) {
      this.rootStore.message(
        "Your token is no longer valid, please repeat forgot password request again.",
        "error",
        { duration: 5 }
      );
      this.rootStore.routingStore.push("/forgot-password");
    }

    return response;
  }

  @action.bound async forgotPasswordReset(token) {
    const body = { password: this.password };

    const errors = this.rootStore.validator.validateForgotPasswordUpdate({
      password: this.password,
      confirmPassword: this.confirmPassword
    });
    if (this.rootStore.hasValidationErrors(errors)) return;

    const { method, url } = this.rootStore.urls.auth.forgotPasswordUpdate;

    const { response } = await this.rootStore.makeRequest({
      method,
      url: `${url}/${token}`,
      body
    });

    if (response) {
      this.rootStore.routingStore.push("/login");
    }

    return response;
  }

  @action.bound async logout() {
    await this.rootStore.api.logout();
  }
}

export default Auth;

import React from "react";
import { observer } from "mobx-react";
import { isEmpty } from "lodash";
import { Scrollbars } from "react-custom-scrollbars";

import Upload from "../Upload";
import Div from "../../Div";
import P from "../../P";
import Popover from "../../Popover";
import UploadedFile from "../../UploadedFile";
import { UploadedFilesWrapper } from "./Styled";

const defaultPopoverInner = (
  <>
    <P bottom={10}>
      Our recommendation is to upload an image in .png or .svg format (no less
      than 250 pixels).
    </P>
    <P>
      The system adapts any image size to the scale of the template. The higher
      resolution of the picture, the better result of future experience.
    </P>
  </>
);

const ImageUpload = ({
  fileName = "",
  fileSrc = "",
  fileNames = [],
  label = "Upload an image",
  labelColor = "#36d453",
  darkIcon = false,
  invert = false,
  popoverPlacement = "right",
  compactMode = false,
  popoverInner = defaultPopoverInner,
  onUpload,
  onRemove,
  onRemoveFromList,
}) => {
  return (
    <Div direction="column" width="100%">
      <Div
        bottom={
          !isEmpty(fileName) && !compactMode
            ? 20
            : !isEmpty(fileName) && compactMode
            ? 5
            : 0
        }
        align="center"
      >
        <Upload
          label={label}
          labelColor={labelColor}
          darkIcon={darkIcon}
          onUpload={onUpload}
        />
        <Popover.InfoPopover placement={popoverPlacement}>
          {popoverInner}
        </Popover.InfoPopover>
      </Div>
      {!isEmpty(fileName) && isEmpty(fileNames) ? (
        <UploadedFile
          src={fileSrc}
          invert={invert}
          fileName={fileName}
          onClick={onRemove}
        />
      ) : null}
      {isEmpty(fileName) && !isEmpty(fileNames) ? (
        <Scrollbars style={{ height: "300px", width: "100%" }} autoHide>
          <UploadedFilesWrapper>
            {fileNames.map((file, i) => (
              <UploadedFile
                key={file.src}
                src={file.src}
                fileName={file.name}
                invert={invert}
                onClick={onRemoveFromList(i)}
              />
            ))}
          </UploadedFilesWrapper>
        </Scrollbars>
      ) : null}
    </Div>
  );
};

export default observer(ImageUpload);

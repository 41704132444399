import React, { useState } from "react";
import { inject, observer } from "mobx-react";
import Icon from "@ant-design/icons-react";
import { ReloadOutline, CompassOutline } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

import { compose } from "utils/hoc";
import { Button } from "components/actions";
import Div from "components/Div";
import Table from "components/Table";
import Timeline from "components/Timeline";
import GeoMap from "features/miscellany/GeoMap";
import { defaultView } from "./settings";

const Dashboard = ({
  mapConfig,
  detailCard,
  detailListView = "table",
  columns,
  items,
  onRefreshData,
  pagination
}) => {
  const [view, setView] = useState(defaultView);

  const centerMap = () => setView({ ...defaultView });
  const itemShow = item => setView({ center: [item.lat, item.lon], zoom: 14 });

  const renderActions = item => (
    <Button type="popoverRegular" onClick={() => itemShow(item)}>
      Locate on Map
    </Button>
  );

  const DetailList = () => {
    switch (detailListView) {
      case "table":
        return <Table columns={columns} data={items} renderActions={renderActions}  paginationConfig={pagination}/>;
      case "timeline":
        return <Timeline data={items} renderActions={renderActions} />;
      default:
        throw new Error("invalid list view");
    }
  };

  return (
    <Div direction="column" width="100%">
      <Div width="100%" justify="center" gap={20} bottom={25}>
        <Div direction="column" justify="flex-start" gap={20} top={10}>
          <Button
            title="Refresh data"
            type="regularRound"
            onClick={onRefreshData}
          >
            <Icon type={ReloadOutline} />
          </Button>
          <Button
            title="Center map"
            type="regularRound"
            onClick={() => centerMap()}
          >
            <Icon type={CompassOutline} />
          </Button>
        </Div>
        <Div>
          <GeoMap
            width={isMobile ? 650 : 900}
            height={350}
            config={mapConfig}
            mapFeaturesOff={[]}
            points={items}
            zoomControl={false}
            view={view}
          />
        </Div>
        <Div>{detailCard}</Div>
      </Div>
      <Div bottom={25}>
        <DetailList />
      </Div>
    </Div>
  );
};

export default compose(
  inject(({ rootStore }) => ({
    rootStore
  })),
  observer
)(Dashboard);

import styled from "styled-components";

export const StyledUploadLabel = styled.label`
  padding-left: 10px;
  font-size: 14px;
  font-weight: bold;
  text-decoration: underline;
  color: ${({ labelColor, theme }) => labelColor || theme.accentColor};
  cursor: pointer;
`;

export const StyledUploadInput = styled.input`
  display: none;
`;
